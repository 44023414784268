import React, { useEffect, useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import Login from "./view/Login/Login";
import NixOptions, { IOptions } from "./components/NixOptions";
import MainRouter from "./Router";
import { BrowserRouter } from 'react-router-dom';
import "./assets/css/card.css";
import LoadDataToCache from "./components/LoadDataToCache";
import { Toaster } from "react-hot-toast";
import "./assets/theme.scss";
// import { downloadDataFromLink } from "./cache/Load.cache";
// import getCache from "./cache/Get.cache";
import { initDB, useIndexedDB } from "react-indexed-db";
import { DBConfig } from "./db/db.config";
import PublicService from "./Services/public.service";
import { unZipFiles } from "./helpers/fs.helper";
import { downloadDataFromLink } from "./cache/Load.cache";
import "react-datepicker/dist/react-datepicker.css";


// initDB(DBConfig);

function App() {

  // const { add, getByID, getByIndex, getAll, deleteRecord, update } = useIndexedDB("dicom");


  const [selected, setSelected] = useState<IOptions[]>();

  const [questionCounter, setquestionCounter] = useState(0);
  const [examCounter, setexamCounter] = useState(0);
  const [totalExam, settotalExam] = useState(0);
  const [totalQuestions, settotalQuestions] = useState(0);

  const [loading, setLoading] = useState(false);



  // const storeDataToIndexDb = async () => {
  //   const liveExamsReq = await PublicService.getAllLiveExams();
  //   const liveExams = liveExamsReq.data.map((data: any) => {
  //     return data._id
  //   })
  //   settotalExam(liveExams.length);

  //   if (!liveExams || liveExams.length === 0) return true;

  //   let examCounter = 0;
  //   setexamCounter(examCounter);

  //   for (const exam of liveExams) {
  //     const examData = await PublicService.getQuestionDataByExamId(exam);
  //     settotalQuestions(examData.data.data.length);
  //     if (examData.data && examData.data.data && examData.data.data.length > 0) {
  //       let questionCounter = 0;
  //       for (const link of examData.data.data) {
  //         const data = await getByIndex("_id", link);
  //         if (!data) {
  //           // await downloadDataFromLink(examData.data.s3Url, link);
  //           const enc_data = await getCache(examData.data.s3Url + "/" + link);
  //           const unzipped_data = await unZipFiles(enc_data)
  //           if (unzipped_data.length > 0) {
  //             const data = await getByIndex("_id", link);
  //             if (!data) {
  //               const added_data = await add({
  //                 _id: link,
  //                 data: unzipped_data
  //               });
  //             }
  //             else {
  //               await deleteRecord(data.id)
  //               const added_data = await add({
  //                 _id: link,
  //                 data: unzipped_data
  //               });
  //             }
  //           }
  //         }
  //         questionCounter = questionCounter + 1;
  //         setquestionCounter(questionCounter)
  //       }
  //     }
  //     examCounter = examCounter + 1;
  //     setexamCounter(examCounter);
  //   }
  //   setLoading(false);
  // }

  // const storeToCache = async () => {
  //   const liveExamsReq = await PublicService.getAllLiveExams();
  //   const liveExams = liveExamsReq.data.map((data: any) => {
  //     return data._id
  //   })

  //   settotalExam(liveExams.length);


  //   if (!liveExams || liveExams.length === 0) return true;
  //   let examCounter = 0;
  //   setexamCounter(examCounter);
  //   for (const exam of liveExams) {
  //     const examData = await PublicService.getQuestionDataByExamId(exam);
  //     localStorage.setItem("onlineUrl", examData.data.s3Url)
  //     settotalQuestions(examData.data.data.length);

  //     if (examData.data && examData.data.data && examData.data.data.length > 0) {
  //       let questionCounter = 0;

  //       for (const link of examData.data.data) {
  //         await downloadDataFromLink(examData.data.s3Url, link);
  //         questionCounter = questionCounter + 1;
  //         setquestionCounter(questionCounter)
  //       }

  //     }

  //     if (examData.data && examData.data.thumbnailsData && examData.data.thumbnailsData && examData.data.thumbnailsData.dicom.length > 0) {
  //       for (const link of examData.data.thumbnailsData.dicom) {
  //         await downloadDataFromLink(examData.data.s3Url, link);
  //       }

  //     }
  //     examCounter = examCounter + 1;
  //     setexamCounter(examCounter);

  //   }


  // }










  // const getDATA = async() => {
  //   console.log( await getCache("https://exmdcm.objectstore.e2enetworks.net/62bad0a312b267768c3d2aa9"))

  // }
  useEffect(() => {
    if (!loading) {
      setLoading(true);
      // storeDataToIndexDb();
      // storeToCache()

    }
    // LoadCache(setCounter, counter);
    // console.log(getDATA())
  }, [])


  // useEffect(() => {
  //   window.addEventListener("beforeunload", handleBeforeUnload);
  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //   };
  // }, []);

  // const handleBeforeUnload = (e:any) => {
  //   e.preventDefault();
  //   const message =
  //     "Are you sure you want to leave?";
  //   e.returnValue = message;
  //   return message;
  // };



  /* View in fullscreen */


  return (
    <div className="App">
      <Toaster
        position="top-center"
        reverseOrder={false}
      />
      {/* <LoadDataToCache /> */}
      <BrowserRouter>
        <MainRouter counter={{
          questionCounter,
          examCounter,
          totalExam,
          totalQuestions
        }} />
      </BrowserRouter>
    </div>
  );
}

export default App;
