import { useEffect, useState } from "react"
import { ExamService } from "../../../Services/examServices"
import { Card, Col, Container, Image, Row } from "react-bootstrap"
import moment from "moment"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronCircleLeft, faEdit } from "@fortawesome/free-solid-svg-icons"
import EditUserInfoModal from "../../../components/Modal/EditUserInfo.modal"
import { useNavigate } from "react-router-dom"

export default function Profile() {
    const navigate = useNavigate();

    const [profile, setProfile] = useState<any>();
    const [showEditModal, setShowEditModal] = useState<boolean>(false);

    const getProfile = async () => {
        await ExamService.getUserInfoWtihoutExams().then((res) => {
            if (res.status === 200) {
                setProfile(res.data.data.studentInfo[0])
            }
        })
    }

    useEffect(() => {
        getProfile();
    }, [])


    return (
        <>
            <Container>
                <div className="d-flex justify-content-between align-items-center mt-5 mb-3">
                    <h5><FontAwesomeIcon className="me-2" icon={faChevronCircleLeft} onClick={() => navigate(-1)} />Details</h5>
                    <FontAwesomeIcon icon={faEdit} onClick={() => setShowEditModal(true)} />
                </div>
                <Card className="px-2" style={{ backgroundColor: "#D0D5DD10", borderRadius: 8, outline: "none", border: "none" }}>
                    <Card.Body>
                        <div className="mt-3">
                            <Row>
                                <Col>
                                    <div className="mb-3" style={{ height: 100, width: 100 }}>
                                        <Image
                                            src={profile?.image ? profile?.image : "https://www.pngitem.com/pimgs/m/581-5813504_avatar-dummy-png-transparent-png.png"}
                                            width="100%"
                                            height={"100%"}
                                            style={{ borderRadius: "50%" }}
                                        />
                                    </div>
                                        <span className="text-dark fw-500">Profile Picture</span>
                                    <div className="mb-3 mt-3 ">
                                        <h5 className="description-label fw-500">Username</h5>
                                        <span className="text-secondary fw-500">{profile?.username}</span>
                                    </div>
                                    <div className="mb-3">
                                        <h5 className="description-label fw-500">Name</h5>
                                        <span className="text-secondary fw-500">{profile?.name}</span>
                                    </div>
                                    <div className="mb-3">
                                        <h5 className="description-label fw-500">Email</h5>
                                        <span className="text-secondary fw-500">{profile?.email}</span>
                                    </div>
                                    <div className="mb-3">
                                        <h5 className="description-label fw-500">Date of Birth</h5>
                                        <span className="text-secondary fw-500">{moment(profile?.dob).format("DD MMM YYYY")}</span>
                                    </div>
                                    <div className="mb-3">
                                        <h5 className="description-label fw-500">Gender</h5>
                                        <span className="text-secondary fw-500">{profile?.gender}</span>
                                    </div>
                                    <div className="mb-3">
                                        <h5 className="description-label fw-500">Mobile</h5>
                                        <span className="text-secondary fw-500">{profile?.mobile}</span>
                                    </div>
                                    <div className="mb-3">
                                        <h5 className="description-label fw-500">Registration No.</h5>
                                        <span className="text-secondary fw-500">{profile?.registrationNumber}</span>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Card.Body>
                </Card>
            </Container>

            <EditUserInfoModal
                show={showEditModal}
                handleClose={() => setShowEditModal(false)}
                data={profile}
                reload={getProfile}
            />
        </>
    )
}