import React, { useState, useEffect } from 'react';
import './DwvComponent.css';



// import Button from '@material-ui/core/Button'
import {Button} from 'react-bootstrap';
import LinearProgress from '@material-ui/core/LinearProgress';
import Slide from '@material-ui/core/Slide';

// import TagsTable from './TagsTable';

import './DwvComponent.css';
// import dwv from 'dwv';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBackwardStep, faClose, faForwardStep, faPause, faPlay } from '@fortawesome/free-solid-svg-icons';



// const dwv = require("dwv");


import {
  App,
  decoderScripts
} from 'dwv';

// Image decoders (for web workers)
decoderScripts.jpeg2000 = `${process.env.REACT_APP_PUBLIC_URL}/assets/dwv/decoders/pdfjs/decode-jpeg2000.js`;
decoderScripts["jpeg-lossless"] = `${process.env.REACT_APP_PUBLIC_URL}/assets/dwv/decoders/rii-mango/decode-jpegloss.js`;
decoderScripts["jpeg-baseline"] = `${process.env.REACT_APP_PUBLIC_URL}/assets/dwv/decoders/pdfjs/decode-jpegbaseline.js`;
decoderScripts.rle = `${process.env.REACT_APP_PUBLIC_URL}/assets/dwv/decoders/dwv/decode-rle.js`;


// Image decoders (for web workers)

// dwv.image.decoderScripts = {
//   jpeg2000: `${process.env.REACT_APP_PUBLIC_URL}/assets/dwv/decoders/pdfjs/decode-jpeg2000.js`,
//   "jpeg-lossless": `${process.env.REACT_APP_PUBLIC_URL}/assets/dwv/decoders/rii-mango/decode-jpegloss.js`,
//   "jpeg-baseline": `${process.env.REACT_APP_PUBLIC_URL}/assets/dwv/decoders/pdfjs/decode-jpegbaseline.js`,
//   rle: `${process.env.REACT_APP_PUBLIC_URL}/assets/dwv/decoders/dwv/decode-rle.js`,
// };

const styles = theme => ({
  button: {
    margin: theme.spacing(1),
  },
  appBar: {
    position: 'relative',
  },
  title: {
    flex: '0 0 auto',
  },
  tagsDialog: {
    minHeight: '90vh', maxHeight: '90vh',
    minWidth: '90vw', maxWidth: '90vw',
  },
  iconSmall: {
    fontSize: 20,
  }
});

export const TransitionUp = React.forwardRef((props, ref) => (
  <Slide direction="up" {...props} ref={ref} />
))

export function DcmViewerHook(props) {

  let filterList = ['Sharpen', 'Sobel'];


  const [tools, setTools] = useState({
    Scroll: {},
    ZoomAndPan: {},
    WindowLevel: {},
    Draw: {
      options: ['Ruler'],
      // , 'Arrow', 'Rectangle', 'Circle'],
      type: 'factory',
      events: ['drawcreate', 'drawchange', 'drawmove', 'drawdelete']
    },
    // Opacity: {},
    // Livewire: {},
    // Filter: {
    //   options: filterList,
    //   type: 'instance',
    //   events: ['filterrun', 'filterundo']
    // },
    // Filter: {
    //   // options: 
    //   // ['Sharpen', 'Sobel']
    // },

    // Floodfill: {
    // events: ['drawcreate', 'drawchange', 'drawmove', 'drawdelete']
    // }

  })
  const [toolNames, settoolNames] = useState([])
  const [selectedTool, setselectedTool] = useState("Select Tool")
  const [loadProgress, setloadProgress] = useState(0)
  const [dataLoaded, setdataLoaded] = useState(false)
  const [dwvApp, setdwvApp] = useState(null)
  const [metaData, setmetaData] = useState([])
  const [toolMenuAnchorEl, settoolMenuAnchorEl] = useState(null)
  const [scroll, setScroll] = useState(0);
  const [windowLevel, setWindowLevel] = useState([]);

  const [reload, setReload] = useState(false);

  const [scrollPlay, setScrollPlay] = useState(false);

  const [drawShape, setDrawShape] = useState(tools.Draw.options[0]);


  const onChangeTool = (tool) => {
    if (dwvApp) {
      setselectedTool(tool);
      dwvApp.setTool(tool);
      if (tool === 'Draw') {
        onChangeShape(tools.Draw.options[0]);
      }

      if (tool === 'Filter') {
        onChangeFilter(tools.Filter.options[0]);
      }

      if (tool === 'Scroll') {
        const layerGroup = dwvApp.getActiveLayerGroup();
        if (layerGroup) {
          var viewController =
            layerGroup.getActiveViewLayer().getViewController();
          viewController.play()
          setScrollPlay(true);
        }
      }
    }
  }

  /**
   * Handle a change draw shape event.
   * @param shape The new shape name.
   */
  const onChangeShape = (shape) => {
    if (dwvApp) {
      setDrawShape(shape);
      // dwvApp.setDrawShape(shape);
      dwvApp.setToolFeatures({shapeName: shape});
    }
  }

  const onChangeFilter = (filter) => {
    if (dwvApp) {
      dwvApp.setImageFilter(filter);
    }
  }

  const runImageFilter = () => {
    if (dwvApp) {
      dwvApp.runImageFilter();
    }
  }

  /**
   * Handle a reset event.
   */
  const onReset = tool => {
    if (dwvApp) {
      // dwvApp.resetDisplay();
      dwvApp.resetZoom();
      dwvApp.reset();
      setdwvApp(null);
      setReload(!reload);
    }
  }



  const nextScroll = () => {
    const layerGroup = dwvApp.getActiveLayerGroup();
    if (layerGroup) {
      var viewController =
        layerGroup.getActiveViewLayer().getViewController();
      viewController.incrementScrollIndex()
    }
  }


  useEffect(() => {
    if (scroll === 1 && scrollPlay === true) {
      onPausePlaytoggle()
    }
  }, [scroll])

  const prevScroll = () => {
    const layerGroup = dwvApp.getActiveLayerGroup();
    if (layerGroup) {
      var viewController =
        layerGroup.getActiveViewLayer().getViewController();
      viewController.decrementScrollIndex()
    }
  }

  const onPausePlaytoggle = () => {
    if (scrollPlay) {
      const layerGroup = dwvApp.getActiveLayerGroup();
      if (layerGroup) {
        var viewController =
          layerGroup.getActiveViewLayer().getViewController();
        viewController.stop()
        setScrollPlay(false)
      }
    }
    else {
      const layerGroup = dwvApp.getActiveLayerGroup();
      if (layerGroup) {
        var viewController =
          layerGroup.getActiveViewLayer().getViewController();
        viewController.play()
        setScrollPlay(true)

      }
    }
  }
  /**
   * Open the DICOM tags dialog.
   */

  /**
   * Menu item click.
   */
  const handleMenuItemClick = tool => {
    settoolMenuAnchorEl(null);
    onChangeTool(tool);
  };

  // drag and drop [begin] -----------------------------------------------------

  /**
   * Setup the data load drop box: add event listeners and set initial size.
   */


  /**
   * Default drag event handling.
   * @param event The event to handle.
   */

  /**
   * Show/hide the data load drop box.
   * @param show True to show the drop box.
   */



  useEffect(() => {
    // create app
    // const dicomDivL = document.getElementById(props.controlId);
    // console.log(dicomDivL)
    // dicomDivL.innerHTML = ""
    // console.log(dicomDivL)

    var app = new App();

    // initialise app
    app.init({
      "dataViewConfigs": { '*': [{ divId: props.controlId }] },
      "tools": tools,
      // "fitToWindow": true
    });

    // load events
    let nLoadItem = null;
    let nReceivedError = null;
    let nReceivedAbort = null;
    let isFirstRender = null;

    app.addEventListener('loadstart', (/*event*/) => {
      // reset flags
      nLoadItem = 0;
      nReceivedError = 0;
      nReceivedAbort = 0;
      isFirstRender = true;
      // hide drop box
    });

    app.addEventListener("loadprogress", (event) => {
      setloadProgress(event.loaded);
    });

    app.addEventListener('renderend', (/*event*/) => {
      if (isFirstRender) {
        isFirstRender = false;
        // available tools
        let names = [];
        for (const key in tools) {
          if ((key === 'Scroll' && app.canScroll()) ||
            (key === 'WindowLevel' && app.canWindowLevel()) ||
            (key !== 'Scroll' && key !== 'WindowLevel')) {
            names.push(key);
          }
        }
        settoolNames(names);
        onChangeTool(names[0]);
      }
    });
    app.addEventListener("load", (/*event*/) => {
      // set dicom tags
      // set data loaded flag
      // if(app.getMetaData(0)){
      //   setmetaData(dwv.utils.objectToArray(app.getMetaData(0)) || undefined)
      // }
      setdataLoaded(true)


    });
    app.addEventListener('loadend', (/*event*/) => {
      if (nReceivedError) {
        setloadProgress(0);
        // alert('Received errors during load. Check log for details.');
        // show drop box if nothing has been loaded

      }
      if (nReceivedAbort) {
        setloadProgress(0);
        alert('Load was aborted.');
      }
    });
    app.addEventListener('loaditem', (/*event*/) => {
      ++nLoadItem;
    });
    app.addEventListener('error', (event) => {
      console.error(event.error);
      ++nReceivedError;
    });
    app.addEventListener('abort', (/*event*/) => {
      ++nReceivedAbort;
    });

    app.addEventListener('positionchange', (event) => {
      const layerGroup = app.getActiveLayerGroup();
      if (layerGroup) {
        var viewController =
          layerGroup.getActiveViewLayer().getViewController();
        if (app.canScroll()) {
          setScroll(viewController.getCurrentScrollIndexValue() + 1);
        }
      }
    })

    app.addEventListener('wlchange', (event) => {
      setWindowLevel(event.value)
    });


    // handle key events
    app.addEventListener('keydown', (event) => {
      app.defaultOnKeydown(event);
    });
    // handle window resize
    window.addEventListener('resize', app.onResize);


    // store
    setdwvApp(app);

    // setup drop box
    handleDataUnzip()


  }, [reload])

  useEffect(() => {
    handleDataUnzip()
  }, [props.dicomData, dwvApp])

  const handleDataUnzip = async () => {
    try{
      if (dwvApp && props.dicomData) {
        dwvApp.loadImageObject(props.dicomData);
      }
    }
    catch(e){
      console.log(e)
    }
    // const unzipped = await unZipFiles(props.dicomData);
    // set unzippedData( unzippe);
    
  }





  return (
    <div id="dwv">
      <LinearProgress variant="determinate" value={loadProgress} />
      <div className="d-flex justify-content-start align-items-center">
        <div className="fw-bold me-4 ms-4 dcm-link-cursor bg-white px-3 py-2 text-danger border border-1 border-danger" onClick={() => props.onClose()}>
          <FontAwesomeIcon icon={faClose} className="me-1" />  Close View
        </div>
        <div className='ms-3'>

          <div style={{
            fontWeight: 'bold',
            lineHeight: 2,

          }} className="text-primary">
          </div>
          <div>
            <Button variant='warning'
              disabled={!dataLoaded}
              onClick={onReset}
              size="sm"
            >Reset Image</Button>
          </div>

        </div>
        <div style={{ minWidth: "20rem" }}>
          {/* <label style={{
            fontWeight: 'bold',
            lineHeight: 2
          }} className="text-primary">
            Select Tool
            {selectedTool === "Scroll" &&
              <span className="text-primary ms-3 ">
                {"(" + scroll + " / " + props.dicomData.length + ")"}
              </span>}
            {selectedTool === "WindowLevel" &&
              <span className="text-primary ms-3 ">
                {"( wc: " + windowLevel[0] + ", ww: " + windowLevel[1] + ")"}
              </span>}
          </label> */}
          {toolNames && toolNames.length > 0 &&
            toolNames.map((tool) => {
              return (
                <Button onClick={() => handleMenuItemClick(tool)} className="ms-2" size="sm" variant={selectedTool == tool ? "primary" : "outline-primary"}>
                  {tool}
                  {tool === "Scroll" &&
                    <span className="text-white fw-bold ms-3 ">
                      {"(" + scroll + " / " + props.dicomData.length + ")"}
                    </span>}
                  {/* {tool === "WindowLevel" &&
                    <span className="text-white fw-bold ms-3 ">
                      {"( wc: " + windowLevel[0] + ", ww: " + windowLevel[1] + ")"}
                    </span>} */}
                    {selectedTool === "Draw"}
                </Button>
              )
            })
            // <Select
            //   onChange={(tool) => handleMenuItemClick(tool.value)}
            //   options={toolNames.map((data) => {
            //     return {
            //       value: data,
            //       label: data
            //     }
            //   })}
            // />
          }
        </div>

        {selectedTool === 'Draw' &&
          <div style={{ minWidth: "10rem" }} className="ms-2">
            {/* <label style={{
              fontWeight: 'bold',
              lineHeight: 2,
              color: "#fff"
            }}>
              Select Shape
            </label> */}
            <Select
              onChange={(e) => onChangeShape(e.value)}
              options={tools.Draw.options.map((data) => {
                return {
                  value: data,
                  label: data
                }
              })}
              value={{
                value: drawShape,
                label: drawShape
              }}
            />
          </div>

        }










        {selectedTool === 'Filter' &&
          <>
            <div style={{ minWidth: "10rem" }} className="ms-2">
              {/* <label style={{
              fontWeight: 'bold',
              lineHeight: 2,
              color: "#fff"
            }}>
              Select Filter
            </label> */}
              <Select
                onChange={(e) => onChangeFilter(e.value)}
                options={tools.Filter.options.map((data) => {
                  return {
                    value: data,
                    label: data
                  }
                })}
              />
            </div>
            <div className='ms-2'>
              {/* <label style={{
                fontWeight: 'bold',
                lineHeight: 2,
                color: "#fff"
              }}>
                Action
              </label> */}
              <Button variant="primary" size="sm" onClick={runImageFilter}>
                Run Filter
              </Button>
            </div>
          </>
        }

        {
          selectedTool === "Scroll" &&
          <div className='ms-3 '>
            <div style={{
              fontWeight: 'bold',
              lineHeight: 2,

            }} className="text-primary">
              Scroll Control
            </div>
            <div className="d-flex justify-content-between align-items-center text-primary">
              <div className="me-2">
                <FontAwesomeIcon className="dcm-link-cursor" icon={faBackwardStep} onClick={prevScroll} />
              </div>
              <div>
                {
                  scrollPlay ? <FontAwesomeIcon className="dcm-link-cursor" icon={faPause} onClick={onPausePlaytoggle} /> :
                    <FontAwesomeIcon className="dcm-link-cursor" icon={faPlay} onClick={onPausePlaytoggle} />
                }

              </div>
              <div className="ms-2">
                <FontAwesomeIcon className="dcm-link-cursor" icon={faForwardStep} onClick={nextScroll} />
              </div>
            </div>
          </div>
        }

      </div>

      <div id={props.controlId} className="layerGroup">
      </div>
    </div>
  );
}