import axios, { Method } from 'axios';
import axiosHandler from '../helpers/axioHandler';
import fingerPrint from '../helpers/fingerprintjs';



export default async function makeUploadRequest(url: string, method: Method, inputPayload?: any){
    let requestConfig = {
        baseURL: `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_VER}`,
        url: url,
        method: method,
        headers: {
            Authorization: sessionStorage.getItem("liveToken") || "",
            "Content-Type": "multipart/form-data",
            devinfo: await fingerPrint()
        },
        data: {}
    };

    // console.log(requestConfig);
    if (method !== 'get' && inputPayload) {
        requestConfig.data = inputPayload;
    }

    try {
        let response = await axios.request(requestConfig);
        return response;
    } catch (error:any) {
        axiosHandler(error);
        throw error;
    }
}