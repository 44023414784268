const url = {
  login: "/auth/userLogin",
  signup: "/public/candidate/signup",
  logout: "/auth/userLogout",
  updateUser: "/user/update/profile",
  exam: {
    getAllExam: "/exam/getAllExam",
    getExamById: "/exam/getExamById",
    startExamId: "/exam/startExam",
    getQuestionDataByDataid: "/exam/getQuestionDataByDataid",
    getUserInfoWithExams: "/exam/getUserInfoWithExams",
    getUserInfoWithoutExams: "/exam/getUserInfoWithoutExams",
  },
  liveExam: {
    getAllQuestionIds: "/liveexam/getAllQuestionIds",
    getUserAllowedStartTime: "/liveexam/getUserAllowedStartTime",
    getLiveExamToken: "/liveExam/getLiveExamToken",
    getExamDetails: "/liveExam/getExamDetails",
    getExamData: "/liveExam/getExamData",
    getResponses: "/liveExam/getResponses",
    getQuestionData: "/liveExam/getQuestionDataByDataid",
    getS3url: "/liveExam/getS3url",
    uploadFile: "/liveExam/upload/file",
    getUserInfo: "/liveExam/getUserInfo",
    markForReview: "/liveExam/markForReview",
    clearResponse: "/liveExam/clearResponse",
    getMarkForReview: "/liveExam/getMarkedReview",
    updatedResponseData: "/liveExam/saveresponse",
    getUserSavedAnswersByQuestionId:
      "/liveExam/getusersavedanswersbyquestionid",
    setExamStatusCompleted: "/liveExam/setExamStatusCompleted",
    getExamInstructions: "/liveExam/getExamInstructions",
    getAllUserEncryptedOptions: "/liveExam/getAllUserEncryptedOptions",
    getInstructions: "/liveExam/getLiveExamInstructions",
    checkUserRemainingTime: "/liveExam/checkUserRemainingTime",
    getQuestionRemarks: "/liveExam/getRemarks",
    markRemarkResolved: "/liveExam/remarks",
    addComment: "/liveExam/remarks/comment",
    submitAllowed: "/liveExam/submit/allowed",
    updateReviewStatus: "/liveExam/review/status",
    getReviewStatus: "/liveExam/review/status",
    getMappingData: "/liveExam/mapping/get",
    getPurpleQuestion:'/liveExam/questions/purple'
  },
  public: {
    getAllLiveExams: "/public/getAllLiveExams",
    getQuestionDataByExamId: "/public/getQuestionDataByExamId",
    forgotPassword: "/public/password/reset/step1",
    resetPassword: "/public/password/reset/step2"
  },
};

export default url;
