import React from 'react';
import { Card, Col, Row, Table } from 'react-bootstrap';
import { IExam } from '../../../../interfaces/IExam';
import DynamicLabel from '../../../../components/Labels/Dynamic.label';
import { createDateFormatWithTime, getTimeFromMins } from '../../../../helpers/date.helper';

interface IUpcoming {
    exams: IExam[]
}


export default function Upcoming(props: IUpcoming) {
    return (

        <Row >
            <Col>
                <Card className="dcm-exam-list-card">
                <Card.Header>
                        <div className='d-flex justify-content-start align-items-center'>
                            Upcoming Exams 
                        </div>
                        

                    </Card.Header>
                    <Card.Body>
                        <Table borderless className="">
                            <thead >
                                <tr>
                                    <th>
                                        Name
                                    </th>
                                    {/* <th>
                                        Start Date
                                    </th>
                                    <th>
                                        End Time
                                    </th> */}
                                    <th>
                                        Subject
                                    </th>
                                    {/* <th>
                                        Duration
                                    </th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {
                                props.exams.length > 0 ?
                                props.exams.map((data: any, index) => {
                                    if(!data.exam) {
                                        return (
                                            <></>
                                        )
                                    }
                                    return (
                                        <tr key={index} className="dcm-table-row-card" >
                                            <td>
                                                <div>
                                                    <span className="fw-bold">
                                                        {data?.exam?.examName}
                                                    </span>
                                                    <span className="ms-2">
                                                        <DynamicLabel
                                                            options={[{
                                                                label: "Live",
                                                                value: "live",
                                                                color: "success"
                                                            }]}
                                                            value="live"
                                                        />
                                                        {
                                                            data.exam.tags.map((tag: string) => {
                                                                return (
                                                                    <DynamicLabel
                                                                        options={[{
                                                                            label: tag,
                                                                            value: tag,
                                                                            color: "success"
                                                                        }]}
                                                                        value={tag}
                                                                    />
                                                                )
                                                            })
                                                        }
                                                    </span>
                                                </div>
                                            </td>

                                            {/* <td>
                                                {createDateFormatWithTime(data.exam.startTime)}
                                            </td>
                                            <td>
                                                {createDateFormatWithTime(data.exam.endTime)}
                                            </td> */}
                                            <td>
                                                {data.exam.subject}
                                            </td>
                                            {/* <td>
                                                {getTimeFromMins(data.exam.duration)} hrs
                                            </td> */}
                                        </tr>
                                    )
                                }):
                                <tr>
                                    <td colSpan={5} className="text-center" style={{fontSize: "14px"}}>
                                        No Upcoming Exams
                                    </td>
                                </tr>
                            }
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    )
}