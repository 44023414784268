import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { unZipFiles, unZipFirstFile } from "../helpers/fs.helper";
import './DwvComponent.css';
import {
  App,
  decoderScripts
} from 'dwv';


// const dwv = require("dwv");


interface IDCMViewerSingle {
  dicomData: any
  renderId: string
}

// Image decoders (for web workers)

// dwv.image.decoderScripts = {
//   jpeg2000: `${process.env.REACT_APP_PUBLIC_URL}/assets/dwv/decoders/pdfjs/decode-jpeg2000.js`,
//   "jpeg-lossless": `${process.env.REACT_APP_PUBLIC_URL}/assets/dwv/decoders/rii-mango/decode-jpegloss.js`,
//   "jpeg-baseline": `${process.env.REACT_APP_PUBLIC_URL}/assets/dwv/decoders/pdfjs/decode-jpegbaseline.js`,
//   rle: `${process.env.REACT_APP_PUBLIC_URL}/assets/dwv/decoders/dwv/decode-rle.js`,
// };

decoderScripts.jpeg2000 = `${process.env.REACT_APP_PUBLIC_URL}/assets/dwv/decoders/pdfjs/decode-jpeg2000.js`;
decoderScripts["jpeg-lossless"] = `${process.env.REACT_APP_PUBLIC_URL}/assets/dwv/decoders/rii-mango/decode-jpegloss.js`;
decoderScripts["jpeg-baseline"] = `${process.env.REACT_APP_PUBLIC_URL}/assets/dwv/decoders/pdfjs/decode-jpegbaseline.js`;
decoderScripts.rle = `${process.env.REACT_APP_PUBLIC_URL}/assets/dwv/decoders/dwv/decode-rle.js`;


export default function DCMViewerSingle(props: IDCMViewerSingle) {

  // const loadUnzipData = async () => {
    
  //   if(props.dicomData){
  //     const unzippedData = await unZipFiles(props.dicomData);
  //     if(unzippedData){
  //       const app = new dwv.App();
  //       app.init({
  //         dataViewConfigs: { '*': [{ divId: props.renderId }] }
  //       });
  //       app.loadImageObject(unzippedData)
  //     }
  //   }
  // }

  const onReloadData = () => {
    const dicomDivL:any = document.getElementById(props.renderId);
    dicomDivL.innerHTML = ''
  }


  const loadUnzipData = async () => {
    
    if(props.dicomData){
      onReloadData();
      const app = new App();
      app.init({
        dataViewConfigs: { '*': [{ divId: props.renderId }] }
      });
      app.loadImageObject([props.dicomData])
    }

    // if(props.dicomData){
    //   const unzippedData = await unZipFiles(props.dicomData);
    //   if(unzippedData){
    //     const app = new dwv.App();
    //     app.init({
    //       dataViewConfigs: { '*': [{ divId: props.renderId }] }
    //     });
    //     app.loadImageObject(unzippedData)
    //   }
    // }
  }

  // console.log(props.dicomData)

  useEffect(() => {
    loadUnzipData()
  }, [props.dicomData])

  return (
    <>
      <div className="dcm-dicom-small-view">
        <div id="dwv">
          <div id={props.renderId} className='layerGroup'>Loading...</div>
        </div>
      </div>
    </>
  )

}
