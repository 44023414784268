import JsZip from 'jszip';

const JSZipUtils = require('jszip-utils');

export async function unZipFiles(file: any) {
    return await new JsZip.external.Promise(async function (resolve, reject) {
       await JSZipUtils.getBinaryContent(file, function (err: any, data: any) {
            if (err) {
                reject(err);
            } else {
                resolve(data);
            }
        });
    }).then(function (data: any) {
        return JsZip.loadAsync(data);
    }).then(async function success(zip) {
        const files = zip.file(/.*/)
        let final_data: any = [];
        // if(files.length === 1){

            // return files[0].async('base64').then(function (content) {
            //     return "data:application/octet-stream;base64,"+content
            // })
            // return await (files[0]);
        // }
        for (let file_data of files) {
            let result = await extractData(file_data);
            final_data.push(result);
        }
        return final_data;
    }, function failure(e) {
        console.error("failure", e);
    });
}


async function extractData(file_data: JsZip.JSZipObject) {
    return new Promise((resolve, reject) => {
        file_data.async('arraybuffer').then(function (content) {
            resolve ({
                filename: file_data.name,
                data: content
            })
        })
    })
}


export async function unZipFirstFile(file: any) {
    return await new JsZip.external.Promise(async function (resolve, reject) {
       await JSZipUtils.getBinaryContent(file, function (err: any, data: any) {
            if (err) {
                reject(err);
            } else {
                resolve(data);
            }
        });
    }).then(function (data: any) {
        return JsZip.loadAsync(data);
    }).then(async function success(zip) {
        const files = zip.file(/.*/)
        let final_data: any = [];
        let result = await extractData(files[0]);
        final_data.push(result);
        return final_data;
    }, function failure(e) {
        console.error("failure", e);
    });
}