import { useState } from "react";
import { Card, Image } from "react-bootstrap";
import ImagePreviewModal from './ImagePreview.modal';
import moment from "moment";

type Props = {
    data: any,
    alignRight?: boolean
}

export default function CommentCard({ data, alignRight }: Props) {

    const [showPreviewModal, setShowPreviewModal] = useState<any>(undefined)

    return (
        <>
            <div className={`d-flex px-2 mb-3 ${!alignRight && 'justify-content-end'}`}>
                <div>
                    {data?.file && (
                        <Card className="shadow-sm mb-3">
                            <Card.Body>
                                <div className="d-flex justify-content-center" style={{ width: "200px", height: '150px' }} onClick={() => setShowPreviewModal(data?.file)}>
                                    <Image src={data.file} alt="Comment File" width="100%" style={{ borderRadius: 8, cursor: "pointer" }} />
                                </div>
                                {data?.comment && <div className="mt-2"><span>{data?.comment}</span></div>}
                            <div className={!alignRight ? 'text-end':'text-start'}>
                            <span className={`fst-italic text-secondary`}>{moment(data.createdAt).format('DD MMMM YYYY, h:mm a')}</span>
                            </div>
                            </Card.Body>
                        </Card>
                    )}
                    {data?.comment && !data?.file && (
                        <div className="mb-3 p-3 d-flex flex-column" style={{ backgroundColor: "white", borderRadius: 30 }}>
                            <span>{data?.comment}</span>
                            <span className={`fst-italic text-secondary ${!alignRight ? 'text-end':'text-start'}`}>{moment(data.createdAt).format('DD MMMM YYYY, h:mm a')}</span>
                        </div>
                    )}
                </div>
            </div>
            <ImagePreviewModal
                show={showPreviewModal}
                handleClose={() => setShowPreviewModal(undefined)}
            />
        </>
    )
}