import { Card } from "react-bootstrap";
import Dropzone from "react-dropzone";

interface IDropzone {
  fileSelectedHandler: any;
  title?: any;
}

export default function FileUpload({ fileSelectedHandler, title }: IDropzone) {
  return (
    <Card>
      <Card.Body>
        <Dropzone onDrop={fileSelectedHandler}>
          {({ getRootProps, getInputProps }: any) => (
            <section>
              <div className="xcn-dropzone-small">
                <div
                  className="d-flex justify-content-center align-itme"
                  {...getRootProps()}
                >
                  <input {...getInputProps()} name="image" multiple />
                  <div className="text-center">
                    <svg
                      width="41"
                      height="41"
                      viewBox="0 0 41 41"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M21.7323 32.5447V25.878H26.7323L20.0656 17.5447L13.3989 25.878H18.3989V32.5447H21.7323Z"
                        fill="#1B2850"
                      />
                      <path
                        d="M11.7323 32.5445H15.0656V29.2112H11.7323C8.97559 29.2112 6.73226 26.9678 6.73226 24.2112C6.73226 21.8712 8.73059 19.6178 11.1873 19.1862L12.1556 19.0162L12.4756 18.0862C13.6473 14.6678 16.5573 12.5445 20.0656 12.5445C24.6606 12.5445 28.3989 16.2828 28.3989 20.8778V22.5445H30.0656C31.9039 22.5445 33.3989 24.0395 33.3989 25.8778C33.3989 27.7162 31.9039 29.2112 30.0656 29.2112H25.0656V32.5445H30.0656C33.7423 32.5445 36.7323 29.5545 36.7323 25.8778C36.7297 24.3839 36.2266 22.9338 35.3034 21.7592C34.3802 20.5847 33.09 19.7533 31.6389 19.3979C30.9106 13.6612 25.9989 9.21118 20.0656 9.21118C15.4723 9.21118 11.4823 11.8962 9.66059 16.1278C6.08059 17.1978 3.39893 20.5778 3.39893 24.2112C3.39893 28.8062 7.13726 32.5445 11.7323 32.5445Z"
                        fill="#1B2850"
                      />
                    </svg>
                    <p className="fs-12 text-secondary">
                      {title ? title : "Select or Drag a file to Upload"}
                    </p>
                  </div>
                </div>
              </div>
            </section>
          )}
        </Dropzone>
      </Card.Body>
    </Card>
  );
}
