import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import toast from 'react-hot-toast';
// import { useIndexedDB } from 'react-indexed-db';
import getCache from '../../../../../cache/Get.cache';
import { DcmViewerHook } from '../../../../../components/DcmViewer.hook';
import DCMViewerSingle from '../../../../../components/DcmViewer.single';
import DicomViewModal from '../../../../../components/DicomViewModal/DicomViewModal';
import decipherData from '../../../../../helpers/Crypt/decrypt';
import { unZipFiles } from '../../../../../helpers/fs.helper';
import LiveExam from '../../../../../Services/liveexam.services';
import { getCachedData } from '../../../../../cache/Load.cache';


interface IExamQuestionValue {
    value: any,
    response: any
}

export default function ExamQuestionValue(props: IExamQuestionValue) {


    const [s3Url, setS3Url] = useState<string>();
    const [decryptedQuestions, setDecryptedQuestions] = useState<any[]>([]);

    const [selectedDicom, setSelectedDicom] = useState<any>(undefined);

    // const { add, getByID, getByIndex, getAll, deleteRecord } = useIndexedDB("dicom");

    const [showDicomFullView, setShowDicomFullView] = useState(false);

    const [loading, setLoading] = useState<boolean>(false);

    const getDataFromStorage = async (valueId: string) => {
        // const decryptedData = await getByIndex("_id", valueId);
        const decryptedData:any = undefined;
        if (decryptedData) {
            return decryptedData?.data;
        }
        else {
            return await LiveExam.getQuestionData(valueId).then(async (res) => {
                if (res.status === 200) {
                    // const data = await getCache(s3Url + "/" + res.data.data._id);
                    const data = await getCachedData(s3Url + "/" + res.data.data._id);

                    //  console.log(s3Url + "/" + res.data.data._id)
                    // console.log(data);
                    // const decryptedData = decipherData(data, res.data.data.secretKey);
                    // return await unZipFiles(data);
                    const unzipped_data = await unZipFiles(data);
                    // console.log(unzipped_data)
                    return unzipped_data;

                    // if (unzipped_data.length > 0) {
                    //     const data = await getByIndex("_id", res.data.data._id);
                    //     if (!data) {
                    //         const added_data = await add({
                    //             _id: res.data.data._id,
                    //             data: unzipped_data
                    //         });
                    //     }
                    //     else {
                    //         await deleteRecord(data.id)
                    //         const added_data = await add({
                    //             _id: res.data.data._id,
                    //             data: unzipped_data
                    //         });
                    //     }

                    // }
                }
            })
        }
    }

    const getQuestionDataById = async () => {
        const valueData = props.value?.questionData.map((data: any) => { return data });
        if (valueData && valueData.length > 0) {
            return await Promise.all(valueData.map((value: any) => { return getDataFromStorage(value) })).then((res) => {
                return res;
                // console.log(res, "resolved")
            })
        }

        return [];

    }

    const requestS3Url = async () => {
        await LiveExam.getS3url().then((res) => {
            if (res.status === 200) {
                setS3Url(res.data)
            }
        })
    }


    const onMount = async () => {
        if (s3Url) {
            const questionData = await getQuestionDataById();
            setDecryptedQuestions(questionData)
            setLoading(false);
        }
    }


    useEffect(() => {
        refreshSelectedDicom()
        if (props.value) {
            setLoading(true);
            setDecryptedQuestions([]);
            onMount();
        }
        else {
            setLoading(false);
            setDecryptedQuestions([]);
        }
    }, [props.value, s3Url])


    useEffect(() => {
        requestS3Url();
    }, [])


    const refreshSelectedDicom = () => {
        setSelectedDicom(undefined)
    }


    const dicomMultiView = () => {
        return (
            <>
                <div className='ms-3'>
                    <div className='dcm-view-dicom-main-area'>
                        <Row>
                            {decryptedQuestions && decryptedQuestions.length > 0 &&
                                decryptedQuestions.map((data: any, index: number) => {
                                    return (
                                        <Col md={3} onClick={() => setSelectedDicom(index)} className="dcm-link-cursor mt-3">
                                            <div className="p-3 border border-primary border-1 dcm-dicom-view-div">
                                                <DCMViewerSingle dicomData={data[0]} renderId={String("layerGroup" + index)} />
                                            </div>
                                        </Col>
                                    )
                                })

                            }
                        </Row>
                    </div>

                </div>
            </>
        )
    }

    console.log(loading, "loading data");

    return (
        <>

            <DicomViewModal
                show={showDicomFullView}
                setShow={setShowDicomFullView}
                dicomData={decryptedQuestions[selectedDicom]}

            />

            {
                loading ? <div className="dicom-half-view">
                    <div className="d-flex justify-content-center align-items-center text-primary">
                    Loading Please Wait...
                    </div>
                </div> :
                    selectedDicom >= 0 ? <div className="dicom-half-view">
                        <div className="d-flex justify-content-center align-items-center">
                            <Button variant="warning" size="sm" onClick={() => setShowDicomFullView(true)}>
                                View Full Screen
                            </Button>
                        </div>

                        <DcmViewerHook
                            dicomData={decryptedQuestions[selectedDicom]}
                            onClose={() => setSelectedDicom(undefined)}
                            controlId="layer_group_main_dicom"

                        />
                    </div> : <>
                        {dicomMultiView()}
                    </>
            }


        </>
    )
} 