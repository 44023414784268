import { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AuthService } from "../../Services/authServices";
import toast from "react-hot-toast";

export default function ResetPassword() {

    const navigate = useNavigate();

    const urlParams = new URLSearchParams(window.location.search);
    const userEmail = urlParams.get('email');


    const [data, setData] = useState<any>();

    const resetPassword = async () => {
        await AuthService.resetPassword(data).then((res) => {
            if (res.status === 200) {
                toast.success("Password updated");
                navigate("/login")
            }
        }).catch(err => {
            toast.error(err.response.data)
        })
    }

    useEffect(() => {
        setData({ ...data, email: userEmail })
    }, [])

    return (
        <>
            <Container>
                <div className="mt-5 d-flex flex-column align-items-center justify-content-center">
                    <h2>Reset account password</h2>
                    <div className="mt-3">
                        <Row>
                            <Col md="12">
                                <Form.Control placeholder="Enter OTP" onChange={(e: any) => setData({ ...data, otp: e.target.value })} />
                            </Col>
                        </Row>
                    </div>
                    <div className="mt-4">
                        <p className="text-secondary" style={{ fontSize: 12 }}>Enter your new password.</p>
                        <Row>
                            <Col md="12">
                                <Form.Control type="text" placeholder="Email" defaultValue={data?.email} disabled={true} />
                            </Col>
                        </Row>
                        <Row className="mt-2">
                            <Col md="12">
                                <Form.Control type="password" placeholder="Password" onChange={(e: any) => setData({ ...data, password: e.target.value })} />
                            </Col>
                        </Row>
                    </div>
                    <div className="mt-3">
                        <Button variant="secondary" className="rounded-0" onClick={resetPassword}>Reset Password</Button>
                    </div>
                </div>
            </Container>
        </>
    )
}