import { faArrowRotateBack, faArrowsRotate, faBroom, faCaretLeft, faCaretRight, faCheck, faCircleExclamation } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext, useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import toast from 'react-hot-toast'
import { useLocation } from 'react-router-dom'
import EndExamConfirmation from '../../../../components/Modal/EndExamConfirmation'
import LiveExam from '../../../../Services/liveexam.services'
import { CustomizedState } from '../../Instructions/Instructions.index'
import { ExamData, ResponsesData, SelectedQuestion } from '../context'
import './footer.css'

interface IExamFooter {
    handleMarkedForReviewChange: any
    onSaveClicked: any
    defaultMarkforReviewState: boolean
    reloadUserAnswers: any
}

export default function ExamFooter(props: IExamFooter) {

    const [markReview, setMarkReview] = useState<boolean>(false);

    const { selectedQuestionId, setSelectedQuestionId } = useContext<any>(SelectedQuestion);
    const { responsesData, setResponsesData } = useContext<any>(ResponsesData);


    const [currentSectionQuestions, setCurrentSectionQuestions] = useState<any[]>([]);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0);

    const nextQuestion = async () => {
        if (currentSectionQuestions.length <= currentQuestionIndex + 1) {
            toast.error("You are on Last Question");
            return
        }
        setSelectedQuestionId({ ...selectedQuestionId, question: currentSectionQuestions[currentQuestionIndex + 1] })
    }

    const handleSaveClicked = async () => {
        const saved = await props.onSaveClicked();
        if (saved) {
            nextQuestion();
        }
    }

    useEffect(() => {
        props.handleMarkedForReviewChange(markReview)
    }, [markReview])

    const previousQuestion = () => {
        if (currentQuestionIndex === 0) {
            toast.error("You are on First Question");
            return
        }
        setSelectedQuestionId({ ...selectedQuestionId, question: currentSectionQuestions[currentQuestionIndex - 1] })
    }

    useEffect(() => {
        setMarkReview(props.defaultMarkforReviewState);
    }, [props.defaultMarkforReviewState])


    const clearResponse = async () => {
        await LiveExam.clearResponse(selectedQuestionId.question).then(res => {
            if (res.status === 200) {
                props.reloadUserAnswers();
            }
        })
    }

    useEffect(() => {
        const sectionData = responsesData?.questionArr.find((res: any) => res.section === selectedQuestionId.section)
        if (sectionData) {
            setCurrentSectionQuestions(sectionData.questions);
            setCurrentQuestionIndex(sectionData.questions.findIndex(((data: any) => data === selectedQuestionId.question)));
        }


    }, [responsesData, selectedQuestionId]);

    // useEffect(() => {
    //     markedReview();
    // }, [])

    return (
        <>
            <div className="xen-footer">
                <div className="d-flex justify-content-between align-items-center m-2">

                    <div className="d-flex justify-content-end align-items-center">
                        {/* {markReview === true ? <>
                            <Button variant="success" className='me-2' onClick={() => setMarkReview(!markReview)}>Marked For Review <FontAwesomeIcon icon={faCheck} /></Button></>
                            : <><Button variant="warning" className='me-2' onClick={() => setMarkReview(!markReview)}>Mark For Review <FontAwesomeIcon icon={faArrowsRotate} /></Button></>} */}
                        {/* <Button variant="secondary" onClick={clearResponse}>Clear Response <FontAwesomeIcon icon={faBroom} /> </Button> */}
                    </div>
                    <div className="d-flex justify-content-end align-items-center">
                        <Button variant="primary" onClick={previousQuestion} className="me-2"><FontAwesomeIcon icon={faCaretLeft} /> Previous</Button>
                        <Button variant="warning" onClick={nextQuestion} className="me-2">Next<FontAwesomeIcon icon={faCaretRight} /> </Button>
                        <Button variant="primary" onClick={handleSaveClicked}>Save & Next <FontAwesomeIcon icon={faCaretRight} /> </Button>
                    </div>

                </div>
            </div>
        </>
    )
}