import React, { useContext, useEffect, useRef, useState } from 'react'
import { SelectedQuestion } from '../../context';
import LiveExam from '../../../../../Services/liveexam.services';
import { Accordion, Badge, Button, Form, Image, Spinner } from 'react-bootstrap';
import CommentCard from '../../../../../components/Comment.card';
import { CgAttachment } from "react-icons/cg";
import toast from 'react-hot-toast';
import ApprovedImage from "../../../../../assets/icons/approved-button.png"
import { GoDotFill } from "react-icons/go";

type Props = {}

export default function Remarks({ }: Props) {
    const { selectedQuestionId } = useContext<any>(SelectedQuestion);
    const [remarks, setRemarks] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [comments, setComments] = useState<{ [key: string]: string }>({});
    const [files, setFiles] = useState<{ [key: string]: File | null }>({});
    const inputImageRefs = useRef<Array<HTMLInputElement | null>>([]);
    const [addCommentLoading, setAddCommentLoading] = useState<boolean>(false);
    const [isApproved, setIsApproved] = useState<boolean>(false);

    console.log(files, "filex")

    async function getRemarks() {
        if (!selectedQuestionId.question) {
            return;
        }
        await LiveExam.getQuestionRemarks(selectedQuestionId.question)
            .then((res: any) => {
                setIsApproved(res.data.data.remarks.isAccepted)
                setRemarks(res.data.data.remarks.remarks);
            })
            .catch((err: any) => {
                console.log(err);
                setRemarks([]);
                setIsApproved(false)
            })
    }

    async function handleClick(id: string) {
        setLoading(true);
        await LiveExam.markRemarkResolved(id)
            .then((res: any) => {
                console.log(res);
            })
            .catch((err: any) => {
                console.log(err);
            })
            .finally(() => {
                setLoading(false);
                getRemarks();
            })
    }

    async function handleAddComment(remarkId: string) {
        if (!remarkId) return;

        const formData = new FormData();
        if (files[remarkId]) {
            formData.append('file', files[remarkId]!);
        }
        if (comments[remarkId]) {
            formData.append('comment', comments[remarkId]);
        }

        setAddCommentLoading(true);
        await LiveExam.addComment(remarkId, formData)
            .then((res: any) => {
                if (res.status === 200) {
                    setComments(prevComments => {
                        const newComments = { ...prevComments };
                        newComments[remarkId] = '';
                        return newComments;
                    });
                    setFiles(prevFiles => {
                        const newFiles = { ...prevFiles };
                        newFiles[remarkId] = null;
                        return newFiles;
                    });
                    getRemarks();
                    toast.success('Comment added successfully');
                }
            })
            .catch((err: any) => {
                console.log(err);
                toast.error(err.response.message);
            })
            .finally(() => {
                setAddCommentLoading(false);
            })
    }

    const handleCommentChange = (remarkId: string, e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setComments(prevComments => ({
            ...prevComments,
            [remarkId]: value
        }));
    };

    useEffect(() => {
        getRemarks();
    }, [selectedQuestionId.question]);

    return (
        <>
            {
                isApproved ? <div className='d-flex justify-content-center'><Image
                    src={ApprovedImage}
                    style={{ height: '11rem', rotate: '-28deg' }}
                /></div> :
                    remarks.length > 0 &&
                    <div className='ms-3 mt-0 bg-light p-3 rounded'>
                        <div className='d-flex justify-content-between align-items-center'>
                            <h5>Remarks from Invigilator</h5>
                        </div>
                        {
                            remarks.map((remark: any, index: number) => {
                                const remarkId = remark._id;
                                return (
                                    <div key={index} className='card p-3 mb-2'>
                                        <span>{remark.description}</span>
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <div className="">
                                                <div className="me-1">
                                                    {
                                                        remark.solvedByStudent ?
                                                            <Badge bg="success" pill>Response Submitted</Badge> :
                                                            <Badge bg="danger" pill>Not Solved</Badge>
                                                    }
                                                </div>
                                                <div className="me-1">
                                                    {
                                                        remark.solved ?
                                                            <Badge bg="success" pill>Accepted</Badge> :
                                                            <Badge bg="danger" pill>Acceptance Awaited</Badge>
                                                    }
                                                </div>
                                            </div>
                                            <div>
                                                {!remark.solvedByStudent &&
                                                    <Button className='float-left' variant="primary" size='sm' onClick={() => handleClick(remarkId)} disabled={loading}>
                                                        {
                                                            !loading ? 'Mark Remark Resolved' : 'Loading'
                                                        }
                                                    </Button>
                                                }
                                            </div>
                                        </div>
                                        <Accordion className='p-0 mt-3'>
                                            <Accordion.Item eventKey={index.toString()}>
                                                <Accordion.Header className='p-0' >View Comments</Accordion.Header>
                                                <Accordion.Body style={{ maxHeight: '550px', overflow: 'scroll' }}>
                                                    {
                                                        remark.comments?.length > 0 ?
                                                            remark.comments.map((comment: any, commentIndex: number) => {
                                                                return (
                                                                    <CommentCard key={commentIndex} data={comment} alignRight={comment.commentUserType === 'INVIGILATOR'} />
                                                                )
                                                            })
                                                            : <p className='text-center fw-bold'>No Comments Found</p>
                                                    }
                                                    <hr />
                                                    <Form.Group className='d-flex align-items-center mt-2 mb-2 px-2'>
                                                        <Form.Control
                                                            className="py-2"
                                                            type="text"
                                                            placeholder='Type comment'
                                                            value={comments[remarkId] || ''}
                                                            onChange={(e: any) => handleCommentChange(remarkId, e)}
                                                        />
                                                        <div className="ms-3" style={{ cursor: 'pointer' }} >
                                                            <div className="d-flex" >
                                                                <CgAttachment style={{ fontSize: 25 }} onClick={() => inputImageRefs[index]?.click()} />
                                                                <sup className="text-success fw-bold">{files[remarkId] && files[remarkId] != null && <GoDotFill />}</sup>
                                                            </div>
                                                            <input type="file" className="d-none" ref={(el: any) => (inputImageRefs[index] = el)} onChange={(e) => {
                                                                if (e.target.files && e.target.files.length > 0) {
                                                                    const file = e.target.files[0];
                                                                    setFiles(prevFiles => ({
                                                                        ...prevFiles,
                                                                        [remarkId]: file
                                                                    }));
                                                                }
                                                            }} accept='image/*' />
                                                        </div>
                                                        <Button className="ms-3 text-nowrap " size='sm' onClick={() => handleAddComment(remarkId)} disabled={loading}>
                                                            Add Comment
                                                            {
                                                                addCommentLoading &&
                                                                <Spinner animation="border" size="sm" className='ms-2' />
                                                            }
                                                        </Button>
                                                    </Form.Group>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </div>
                                )
                            })
                        }
                    </div>
            }
        </>
    )
}
