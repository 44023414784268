import React from 'react';
import DashboardNav from '../../../components/Navbar/DashboardNav';
import ListExams from './ListExams/ListExams';

export default function ExamDashboard() {
    return (
        <div>
            
            <DashboardNav/>
            <div className="mt-3">
                <ListExams />
            </div>
        </div>
    )
}