import React, { useContext, useEffect, useState } from 'react';
import { Col } from 'react-bootstrap';
import { ExamData, ExamDetails, ResponsesData, SelectedQuestion } from '../context';
import ExamSidebar from '../ExamSidebar/ExamSidebar';
import ExamQuestion from './ExamQuestion/ExamQuestion';
import { IOptionsData } from './ExamQuestion/ExamQuestion.options';


import "./ExamQuestionBody.css";
interface IExamQuestionBody {
    onUserResponseChange: any
    userAnswers: IOptionsData[] | undefined
    disabled: boolean
}

export default function ExamQuestionBody(props: IExamQuestionBody) {
    const examData: any = useContext(ExamData);
    const examDetails: any = useContext(ExamDetails);
    const { responsesData, setResponsesData } = useContext<any>(ResponsesData);

    const { selectedQuestionId, setSelectedQuestionId } = useContext<any>(SelectedQuestion);

    const [selectedQuestionIndex, setSelectedQuestionIndex] = useState<number>(0);
    const [totalQuestions, setTotalQuestions] = useState<number>(0);

    const [currentSectionData, setCurrentSectionData] = useState<any>();

    const [selectedQuestionData, setSelectedQuestionData] = useState<any>();


    useEffect(() => {
        const sectionData = responsesData?.questionArr.find((res: any) => res.section === selectedQuestionId.section)
        const questionIndex = sectionData?.questions.findIndex((i: any) => i === selectedQuestionId.question)
        setSelectedQuestionIndex(questionIndex || 0);
        setCurrentSectionData(sectionData);
        setTotalQuestions(sectionData?.questions?.length || 0)
    },[selectedQuestionId, responsesData])



    const extractSelectedQuestionData = () => {
        const question_section = examData?.data.find((section: any) => section._id === selectedQuestionId?.section);
        if (question_section) {
            const question_selected_data = question_section?.questionData?.find((question: any) => question._id === selectedQuestionId.question);
            setSelectedQuestionData(question_selected_data)
        }
    }


    const handleReponseChange = (e:any) => {
        props.onUserResponseChange({
            questionId: selectedQuestionId.question,
            optionsData: e
        })
    }

    useEffect(() => {
        extractSelectedQuestionData()
    }, [examData, selectedQuestionId])

    return (
        <>
            <div className="fw-bold text-secondary d-flex justify-content-between">
                <span className="ms-2">
                    Question Number: {selectedQuestionIndex + 1}
                </span>
                <span className="me-4 text-dcm-primary">
                    Total Questions: {totalQuestions}
                </span>
            </div>
            <div className="d-flex justify-content-start">
                <ExamSidebar questionIndex={selectedQuestionIndex} sectionData={currentSectionData} />
                <div style={{ width: '100%' }}>
                    {selectedQuestionData ?
                        <ExamQuestion
                            questionData={selectedQuestionData}
                            questionResponses={currentSectionData}
                            language={selectedQuestionId?.language || "english"}
                            onUserResponseChange={handleReponseChange}
                            userAnswers={props.userAnswers}
                            disabled={props.disabled}
                        /> : "Loading Data.."}
                </div>
            </div>
        </>
    )
}