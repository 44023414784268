import { faGears, faRightFromBracket, faUserTie } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Form, Navbar, Container, Nav, Image, NavDropdown } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';
import Auth from '../../auth/auth';
import fingerPrint from '../../helpers/fingerprintjs';
import { AuthService } from '../../Services/authServices';
import "./dashboardnav.css";
import logo from "../../assets/icons/x-rays.png";
import toast from 'react-hot-toast';
import { ExamService } from '../../Services/examServices';

export default function DashboardNav() {
    const navigate = useNavigate();

    const [profile, setProfile] = useState<any>()

    const getProfile = async() => {
        await ExamService.getUserInfoWtihoutExams().then((res) => {
            if(res.status === 200) {
                setProfile(res.data.data.studentInfo[0])
            }
        })
    }

    const handelClick = async () => {
        await AuthService.logout(({
            devInfo: await fingerPrint()
        }))
            .then(async (res) => {
                if (res.status === 200) {
                    await Auth.signout();
                    localStorage.setItem("authKey", res.data.data.token)
                    toast.success("loged out")
                }
                navigate('/login')
            })
            .catch((err) => {
                if (err.response.status === 403) {
                    console.error(err);
                }
            })
    };


    useEffect(() => {
        getProfile();
    }, [])

    return (
        <Navbar bg="dcm-white" expand="lg" className="dcm-navbar">
            <Container>
                <Navbar.Brand><Image src={logo} className="dcm-logo-image" /> <span> NIXASSIGN</span></Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link>Dashboard</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
                <div
                    className="d-flex align-items-center"
                    style={{ cursor: "pointer" }}
                >
                    <div className="me-2" style={{ height: 40, width: 40 }}>
                        <Image
                            src={profile?.image ? profile?.image : "https://www.pngitem.com/pimgs/m/581-5813504_avatar-dummy-png-transparent-png.png"}
                            width="100%"
                            height={"100%"}
                            style={{ borderRadius: "50%" }}
                        />
                    </div>
                    <NavDropdown
                        title={profile?.name}
                        id="basic-nav-dropdown"
                    >
                        <NavDropdown.Item
                            className="text-muted"
                            style={{ fontWeight: "400" }}
                            onClick  ={() => navigate("/exam/user/profile")}
                        >
                            <FontAwesomeIcon icon={faUserTie} className="me-2" style={{ fontSize: 18 }} />
                            Profile
                        </NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item className="text-danger" onClick={handelClick}>
                            <span className="dcm-link-cursor fw-bold" >Logout <FontAwesomeIcon icon={faRightFromBracket} /></span>
                        </NavDropdown.Item>
                    </NavDropdown>
                </div>
            </Container>
        </Navbar>
    )
}