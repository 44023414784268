import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Card } from 'react-bootstrap';
import { timer } from '../../../helpers/timer/timer';
import ShowTimer from './Timer/ShowTimer';
import { useLocation, useNavigate } from "react-router-dom";
// import ExamAlertShow from './Timer/ExamALertShow';
import DashboardNav from '../../../components/Navbar/DashboardNav';
import { instructionData } from '../../../data/data';
import Clock from '../../../helpers/Countdown/Clock';
import { ExamService } from '../../../Services/examServices';
import Countdown, { calcTimeDelta } from "react-countdown";
import toast from 'react-hot-toast';
import LiveExam from '../../../Services/liveexam.services';
import { errorResponseHelper } from '../../../helpers/request.helper';
import AllPagesPDFViewer from './pdfview/AllPagesPdfViewer';

import useCountDown from 'react-countdown-hook';
import prettyMilliseconds from 'pretty-ms';
import { Document } from 'react-pdf';
import "./Instructions.css";
export interface CustomizedState {
    examId: string
    mappingId: string
}

export default function Instructions() {

    const [second, setSeconds] = useState(10);
    const [modalShow, setModalShow] = React.useState(false);
    // const [examAlertShow, setExamAlertShow] = React.useState(false);
    const [timeLeft, { start, pause, resume, reset }] = useCountDown(0, 1000);

    const [countdownTimer, setCountdownTimer] = useState<number>();
    const [disable, setDisable] = useState(true);


    let time = timer(second, setSeconds);
    const navigate = useNavigate();
    const location = useLocation();
    const state = location.state as CustomizedState

    const [instructions, setInstructions] = useState<string[]>()


    const generateTokenForLiveExam = async () => {
        await LiveExam.generateTokenForLiveExam(state.examId, state.mappingId).then((res) => {
            if (res.status === 200) {
                sessionStorage.setItem("liveToken", res.data.data.token);
                toast.success("Exam CountDown Has begun");
                navigate('/live/exam-live', { state: { examId: state.examId, mappingId: state.mappingId } });
            }
        }).catch(e => {
            errorResponseHelper(e)
        })
    }

    const startExam = () => {
        generateTokenForLiveExam()
    };

    //Countdown timer

    const getExamInstructions = async () => {
        await ExamService.getInstructions(state.examId).then((res) => {
            if (res.status === 200) {
                setInstructions(res.data.data.instructions);
            }
        }).catch(e => {
            console.error(e)
        })
    }






    // const startCountdown = async () => {
    //     await ExamService.getUserAllowedStartTime(state.examId)
    //         .then((res) => {
    //             if (res.status === 200) {
    //                 if (res.data) {
    //                     start(res.data.data.startTime * 1000)
    //                 }
    //             }
    //         })
    // }

    useEffect(() => {
        // startCountdown();
        getExamInstructions();
    }, [])

    const timeComplete = () => {
        setDisable(false)
        pause()
    }


    useEffect(() => {
        if (timeLeft <= 0) {
            timeComplete()
        }
        else {
            setDisable(true)
        }
    }, [timeLeft])


    const ulStyle = {
        listStyle: 'none'
    }

    return (
        <>
            <DashboardNav />
            <Container className="bg-white exam-content rounded shadow my-2 p-3">
                <div className="text-left">
                    <h1 ><b>Important Instructions</b></h1>
                </div>
                <Row className="p-3">
                    <Col xs="12">
                        <div className="all-page-container mt-3">
                        {
                            instructions && <>
                            <AllPagesPDFViewer pdf={instructions} />
                            </>
                        }
                        </div>
                    </Col>
                </Row>
                {/* <Row>
                    <Card
                        style={{ fontWeight: 'bold', fontSize: '2rem', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        {
                            timeLeft <= 0 ? <>
                                You can Start Your Exam!
                            </> :
                                <>
                                    <p style={{ fontSize: '12px', fontWeight: 'light' }}>Exam will start after : </p>
                                    {prettyMilliseconds(timeLeft)}
                                </>
                        }
                    </Card>
                </Row> */}
                <Row className="float-right m-4">
                    {disable ? <Button color="primary" disabled onClick={() => toast.error("Exam Not Started Yet!")}>
                        Start Exam
                    </Button> :
                        <Button color="primary" onClick={startExam}>
                            Start Exam
                        </Button>}

                </Row>
                {/* <ShowTimer
                    time={time}
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                /> */}
                {/* <ExamAlertShow
                    show={examAlertShow}
                    onHide={() => setExamAlertShow(false)}
                /> */}
            </Container>
        </>
    )
}




// <ul className='d-grid gap-3'>
//                             {instructions && instructions.map((instruction: string, index: number) => {
//                                 return (
//                                     <li>{instruction}</li>
//                                 )
//                             })}
//                             {/* <li>{instructionData.option1}</li>
//                             <li>{instructionData.option2}</li>
//                             <li>{instructionData.option3}</li>
//                             <li>{instructionData.option4}</li>
//                             <li>{instructionData.option5}</li>
//                             <li>{instructionData.option6}</li>
//                             <li>{instructionData.option7}</li>
//                             <li>{instructionData.option8}{" "}</li> */}
//                             <li>
//                                 {instructionData.option9}
//                                 <ul className='d-grid gap-4 mt-4' style={ulStyle}>
//                                     {/* <li className='answered d-flex justify-content-start'>
//                                         <div className="d-flex justify-content-center"></div>
//                                         <b>{instructionData.optionData.option1}</b>
//                                     </li>
//                                     <li className='notanswered d-flex justify-content-start'>
//                                         <div className="d-flex justify-content-center"></div>
//                                         <b>{instructionData.optionData.option2}</b>
//                                     </li> */}
//                                     <li className='notvisited d-flex justify-content-start'>
//                                         <div className="d-flex justify-content-center"></div>
//                                         <b>{instructionData.optionData.option3}</b>
//                                     </li>
//                                     <li className='d-flex justify-content-start align-items-center'>
//                                         <div className="hexagon "></div>
//                                         <b className='ms-2'>{instructionData.optionData.option4}</b>

//                                     </li>

//                                     <li className='markedanswer d-flex justify-content-start'>
//                                         <div className="d-flex justify-content-center"></div>
//                                         <b>{instructionData.optionData.option5}</b>
//                                     </li>
//                                 </ul>

//                             </li>
//                         </ul>