import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Modal } from 'react-bootstrap';
import DwvComponent from '../DcmViewer';
import { DcmViewerHook } from '../DcmViewer.hook';
import DicomViewer from '../DicomViewer';

import "./DicomViewModal.css";

interface IDicomViewModal {
  show: boolean
  setShow: any
  dicomData: any
}

export default function DicomViewModal(props: IDicomViewModal) {

  return (
    <>
      <Modal show={props.show} fullscreen={true} onHide={() => props.setShow(false)} centered  className="dicom-modal-fullscreen">
        <Modal.Title className="text-white m-3"><FontAwesomeIcon icon={faClose} onClick={() => props.setShow(false)} className="me-3 dcm-link-cursor"/> Dicom Control View</Modal.Title>
        <Modal.Body>
          <DcmViewerHook
            dicomData={props.dicomData}
            onClose={() => props.setShow(false)}
            controlId="layer_group_modal_dicom"
          />
        </Modal.Body>
      </Modal>
    </>
  );
}