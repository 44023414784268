import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { AuthService } from "../../Services/authServices";
import { useNavigate } from "react-router-dom";

interface IForgotPasswordModal {
    show: any,
    handleClose: any
}

export default function ForgotPasswordModal({ show, handleClose }: IForgotPasswordModal) {

    const navigate = useNavigate();


    const [email, setEmail] = useState<any>();

    console.log(email)


    const handleForgotPassword = async () => {
        await AuthService.forgotPassword(email).then((res: any) => {
            if (res.status === 200) {
                navigate(`/reset-password?email=${email?.email}`)
            }
        })
    }

    return (
        <>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                animation={true}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <h3>Reset your password</h3>
                    <p className="text-secondary" style={{ fontSize: 12 }}>Enter your email and we'll send you OTP on your email to reset password.</p>
                    <Form.Group>
                        <Form.Control type="email" onChange={(e: any) => setEmail({ ...email, email: e.target.value })} />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-danger" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="secondary" onClick={handleForgotPassword}>
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}