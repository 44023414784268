import React from 'react'
import { Answered, Marked, NotAnswered, NotVisited, MarkedandAnswered } from '../../../../components/Marking/Marking'

export default function QuestionInfo({
    answered,
    marked,
    notVisited,
    markedandAnswered,
    visited
}:any) {
    console.log(notVisited)
    return (
        <>
            <ul className="d-flex justify-content-between align-items-center">
                <Answered count={answered} onClick={()=>{}}/>
                {/* <Marked count={marked} onClick={()=>{}}/> */}
                <NotVisited count={notVisited} onClick={()=>{}}/>
                {/* <MarkedandAnswered count={markedandAnswered} onClick={()=>{}}/> */}
            </ul>
        </>
    )
}