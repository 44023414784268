import { faFlag, faFontAwesomeFlag } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import { SelectedQuestion } from '../../view/Private/Exam/context';


interface IChoiceOption {
    choiceLabel: any
    textLabel: any
    count: number
    onChange: any
    optionId: string
    defaultValue?: any
    disabled?: boolean

}

export default function ChoiceTextOption(props: IChoiceOption) {
    
    const { selectedQuestionId, setSelectedQuestionId } = useContext<any>(SelectedQuestion);


    const [isSelected, setSelected] = useState(props.defaultValue && props.defaultValue.value ? props.defaultValue.value : false);
    const [flagBoolean, setFLagBoolean] = useState<boolean>(false);
    const [textBoxData, setTextBoxData] = useState<string>("");

    useEffect(() => {
        props.onChange({
            optionId: props.optionId,
            value: isSelected,
            child: textBoxData,
            flag: flagBoolean
        });
    }, [isSelected, flagBoolean, textBoxData, selectedQuestionId]);

    const handleChange = () => {
        setSelected(!isSelected)
    }

    const handleTextChange = (e: any) => {
        setTextBoxData(e.target.value)
    }

    useEffect(()=>{
        if(props.defaultValue && props.defaultValue.value === true){
            setSelected(true)
        }
        else{
            setSelected(false)
        }

        if(props.defaultValue && props.defaultValue.child){
            setTextBoxData(props.defaultValue.child)
        }
        else{
            setTextBoxData("")
        }

    },[props.defaultValue])

    return (
        <>
            <Form.Group>
                <Form.Check
                    onClick={handleChange}
                    // checked={isSelected}
                    type="radio"
                    checked={isSelected}
                    label={<div className="h5 d-flex justify-content-end align-items-center">
                        <div>
                            <span className="fw-bold">
                                {String((props.count) + ".")}
                                {/* {String((props.count + 9).toString(36).toUpperCase() + ".")} */}
                            </span>
                            <span className='ms-1'>
                                {props.choiceLabel}
                            </span>
                        </div>

                    </div>}
                />
            </Form.Group>

            {
                isSelected &&
                <Form.Group className='mt-2 mb-2'>
                    <Form.Label className="h5">
                        <span className='ms-1 fw-bold text-danger'>
                            {props.textLabel}
                        </span>
                    </Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={5}
                        onChange={handleTextChange}
                        value={textBoxData}
                        disabled={props.disabled}

                    />
                </Form.Group>
            }


        </>
    )
}