import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { Alert, Button, Card, Image } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { Navigate, useNavigate } from 'react-router-dom';
import { ExamService } from '../../../../Services/examServices';

const StudentInfo = () => {

    const navigate = useNavigate();

    const [userInfo, setUserInfo] = useState<any>();

    async function getUserInfoWithoutExams() {
        await ExamService.getUserInfoWtihoutExams()
            .then((res) => {
                if (res.status === 200) {
                    setUserInfo(res.data.data);
                    toast.success("Data fetched successfully");

                }
            })
            .catch((err) => {
                console.log(err);
            })
    }

    useEffect(() => {
        getUserInfoWithoutExams();
    }, [])

    return (
        <>
            <Card className="mt-4 mb-4 dcm-exam-list-card" style={{ maxWidth: "60rem", margin: "0 auto" }}>
                <Card.Header>Verify Your Information</Card.Header>
                <Alert variant='warning'><b> <FontAwesomeIcon icon={faTriangleExclamation} className="ms-2"/> Note:</b> If You find any issues in the below Information, contact invigilator</Alert>
                <Card.Body>
                    {userInfo && userInfo.studentInfo.map((data: any) => {
                        return (
                            <div className='d-flex justify-content-between align-items-center'>

                                <div>
                                    <span>Name:</span> <span className="fw-bold">{data?.name + " " + data?.last_name}</span> <br /><br />
                                    <span>Email:</span> <span className="fw-bold">{data?.email}</span><br /><br />
                                    {/* <span>Date of Birth:</span> <span className='fw-bold'>{createDateFormat(data?.dob)}</span> <br /><br /> */}
                                    <span>Reg Number:</span> <span className='fw-bold'>{data?.registrationNumber || "---"}</span> <br /><br />
                                    <span>Membership Number:</span> <span className='fw-bold'>{data?.membershipNumber || "---"}</span> <br /><br />
                                </div>
                                <div className="mb-3 border border-1 border-secondary p-2">
                                    <Image src={data?.image} className="xen-dash-logo ml-4" style={{ height: "100px", width: "100px" }} />
                                </div>

                            </div>
                        )
                    })}
                    <div>
                        <Button onClick={() => navigate("/exam/exam-dashboard")}>Verify and Next</Button>
                    </div>
                </Card.Body>
            </Card>

        </>
    )
}

export default StudentInfo