import makeRequest from "../api/makeRequest";
import url from "../api/urls";
import { RequstMethods } from "../api/requestMethode";

export class AuthService {
    static async login(inputPayload: any) {
        return await makeRequest(url.login, RequstMethods.POST, inputPayload)
    }
    static async signup(inputPayload: any) {
        return await makeRequest(url.signup, RequstMethods.POST, inputPayload)
    }

    static async logout(inputPayload: any) {
        return await makeRequest(url.logout, RequstMethods.PUT, inputPayload)
    }

    static async forgotPassword(email: string) {
        return await makeRequest(url.public.forgotPassword, RequstMethods.POST, email);
    }

    static async resetPassword(payload: string) {
        return await makeRequest(url.public.resetPassword, RequstMethods.POST, payload);
    }

    static async updateuser(payload: string) {
        return await makeRequest(url.updateUser, RequstMethods.POST, payload);
    }
}