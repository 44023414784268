import React, { useEffect, useState } from "react";
import { Container, Card, Col, Row, Button, FloatingLabel, Form, DropdownButton, Dropdown, Image } from "react-bootstrap";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Auth from "../../auth/auth";
import home_logo from "../../assets/icons/ICRI_NEW.png";
import fingerPrint from "../../helpers/fingerprintjs";
import { AuthService } from "../../Services/authServices";
import LoginAnimation from "../../assets/lottie/user-login.json"
import Lottie from "lottie-react";
import LoginSvg from "../../assets/icons/login.svg"
import ForgotPasswordModal from "../../components/Modal/ForgotPassword.modal";

export default function Login() {


  // const login_svg = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  // <path d="M15.75 9V5.25C15.75 4.65326 15.5129 4.08097 15.091 3.65901C14.669 3.23705 14.0967 3 13.5 3H7.5C6.90326 3 6.33097 3.23705 5.90901 3.65901C5.48705 4.08097 5.25 4.65326 5.25 5.25V18.75C5.25 19.3467 5.48705 19.919 5.90901 20.341C6.33097 20.7629 6.90326 21 7.5 21H13.5C14.0967 21 14.669 20.7629 15.091 20.341C15.5129 19.919 15.75 19.3467 15.75 18.75V15M18.75 15L21.75 12M21.75 12L18.75 9M21.75 12H9" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  // </svg>
  // `


  const navigate = useNavigate();
  const [loginData, setLoginData] = useState<any>({
    email: "",
    password: "",
  });

  const [showForgotModal, setShowForgotModal] = useState<boolean>(false)


  const onChangeValue = (e: any) => {
    setLoginData({ ...loginData, [e.target.name]: e.target.value });
  };

  const loginSubmit = async (e: any) => {
    e.preventDefault();

    await AuthService.login(({
      email: loginData.email,
      password: loginData.password,
      devInfo: await fingerPrint()
    }))
      .then(async (res) => {
        if (res.status === 200) {
          await Auth.authenticate();
          localStorage.setItem("authKey", res.data.data.token)
          toast.success("Login Success")
          navigate('/exam/student-info')
        }
      })
      .catch((err) => {
        if (err.response.status === 403) {
          console.error(err);
          toast.error("Something went wrong")
        } else if (err.response.status === 500) {
          // alert("Alredy Loged in")
          toast.error(err.response.data)

          console.error(err.response.data);
        }
      })
  };

  return (
    <>
      <div style={{ height: "100vh", backgroundColor: '#eeeeee' }}>
        <Container style={{ maxWidth: "400px", paddingTop: "50px" }} >
          <Lottie animationData={LoginAnimation} style={{ height: "230px" }} loop={true} height={100} width={100} />
          <Row className="justify-content-md-center mt-3">
            <Col md="12">
              <FloatingLabel
                controlId="floatingInput"
                label="User Name"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  placeholder="Username"
                  name="email"
                  onChange={onChangeValue}
                  style={{ borderColor: "rgb(191,146,251)" }}
                />
              </FloatingLabel>
              <FloatingLabel
                controlId="floatingPassword"
                label="Password"
                onChange={onChangeValue}
              >
                <Form.Control
                  type="password"
                  placeholder="Password"
                  name="password"
                  style={{ borderColor: "rgb(191,146,251)" }}
                />
              </FloatingLabel>
              <div className="mt-2 d-flex justify-content-end" style={{ cursor: "pointer" }} onClick={() => setShowForgotModal(true)}>
                <Form.Text muted>
                  Forgot Password
                </Form.Text>
              </div>
              <Button
                type="submit"
                className="w-100 mt-4"
                size="lg"
                onClick={loginSubmit}
                style={{ backgroundColor: "rgb(191,146,251)", border: "none", outline: "none" }}
              >
                <Image src={LoginSvg} className="me-2" />
                <span className="xcn-text-14">
                  Login
                </span>
              </Button>
              <hr />
              <div className="d-flex justify-content-center">
                <span className="text-center">Don't have an account? <span className="" style={{ cursor: "pointer", color: "rgb(191,146,251)", fontWeight: "500" }} onClick={() => navigate('/signup')}>Signup</span></span>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <ForgotPasswordModal
        show={showForgotModal}
        handleClose={() => setShowForgotModal(false)}
      />
    </>
  );
};
