import React from 'react'
import { Badge } from 'react-bootstrap'

interface IDynamicLabel {
    value: any
    options: [{
        label: any,
        value: any,
        color: "success" | "primary" | "secondary" | "danger" | "warning" | "info"
    }]
}

export default function DynamicLabel (props: IDynamicLabel){
    const selectedOption = props.options.find((data => data.value === props.value));
    if(selectedOption){
        return <Badge className='me-1' bg={selectedOption.color}>{selectedOption.label}</Badge>
    }
    else{
        return (
            <Badge bg="warning">No Value Exist</Badge>
        )
    }
}