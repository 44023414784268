import { Document, Page, pdfjs } from 'react-pdf';
import { Image, Modal } from 'react-bootstrap';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

// pdfjs worker
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

interface IImagePreviewModal {
    show: string;
    handleClose: () => void;
}

export default function ImagePreviewModal({ show, handleClose }: IImagePreviewModal) {
    const isPdf = (url: string) => {
        if(!url) return false;
        return url.match(/\.(pdf)$/) != null;
    };
    const pdfScale = 1.5; // Adjust scale for better resolution
    return (
        <Modal size="xl" fullscreen show={!!show} onHide={handleClose} animation={true} centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className='d-flex justify-content-center'>
            {isPdf(show) ? (
                <div style={{ overflowX: 'auto' }} className="hide-text-layer">
                    <Document file={show}>
                        <Page pageNumber={1} scale={pdfScale} />
                    </Document>
                </div>
            ) : (
                <Image src={show} width={"90%"} />
            )}
        </Modal.Body>
    </Modal>
    );
}
