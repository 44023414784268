import FileUpload from "../Dropzone/dropzone"
import { faBroom, faEye, faFlag, faFontAwesomeFlag, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form } from "react-bootstrap";
import { StringHelper } from "../../helpers/string.helper";
import { useEffect, useState } from "react";
import ImagePreviewModal from "../Modal/ImagePreview.modal";
import toast from "react-hot-toast";
import { FileUploadService } from "../../Services/fileUploadServices";

interface IFileUploadAllowed {
    label: any
    count: number
    onChange: any
    optionId: string
    defaultValue?: any
    fileUploadAllowed?: any,
    disabled?:boolean
}

export default function FileUploadAllowed(props: IFileUploadAllowed) {

    const [file, setFile] = useState<any>([])

    const [showPreviewModal, setShowPreviewModal] = useState<any>(undefined)

    const handleFileDelete = (index: number) => {
        let newFiles = [...file];
        newFiles.splice(index, 1);
        setFile(newFiles);
    }

    useEffect(() => {
        if(file && file.length > 0) {
            props.onChange({optionId: props.optionId, value: file})
        }
    },[file])

    useEffect(() => {
        if(props.defaultValue && props.defaultValue.value && Array.isArray(props.defaultValue.value)) {
            setFile(props.defaultValue.value)
        }
        else{
            setFile([])
        }
    },[props.defaultValue])


    const handleFileUpload = async (e: any) => {
        const formData = new FormData();
        formData.append("file", e)
        toast.promise(
            FileUploadService.fileUplaod(formData).then((res) => {
                setFile((arr: any) => [...arr, res.data.data.fileUrl])
            }),
            {
                loading: 'Uploading...',
                success: "File Uploaded",
                error: "Unable to upload file",
            }
        );
    }

    return (
        <div className='mb-4'>
            <Form.Group>
                <Form.Label className="h5 d-flex justify-content-between align-items-center">
                    <div className="fs-14">
                        <span className="fw-bold">
                            {String((props.count + 9).toString(36).toUpperCase() + ".")}
                        </span>
                        <span className='ms-1'>
                            {props.label}
                        </span>
                    </div>
                    {/* <div>
                        <span className='me-2'>
                            <FontAwesomeIcon icon={faBroom} style={{ cursor: "pointer" }} size="sm" />
                        </span>
                        <span>
                            {
                                props.defaultValue && props.defaultValue.flag === true ?
                                    <>
                                        <FontAwesomeIcon className="text-warning" icon={faFlag} />
                                    </> :
                                    <>
                                        <FontAwesomeIcon icon={faFontAwesomeFlag} style={{ cursor: "pointer" }} />
                                    </>
                            }
                        </span>
                    </div> */}
                </Form.Label>
                <div>
                    {!props.disabled && <FileUpload fileSelectedHandler={(e: any) => handleFileUpload(e[0])} /> }
                </div>
                <div className="mt-2">
                    {file && file.length > 0 && file.map((data: any, index: number) => {
                        return (
                            <div className="d-flex mt-1" key={data + index}>
                                <div className="d-flex">
                                    <p className="ms-2 text-secondary">Image {index + 1}</p>
                                </div>
                                <div className="ms-3">
                                    <FontAwesomeIcon icon={faEye} className="text-secondary" style={{ cursor: "pointer" }} onClick={() => setShowPreviewModal(data)} />
                                    {!props.disabled && <FontAwesomeIcon icon={faTrash} className="text-danger ms-3" style={{ cursor: "pointer" }} onClick={() => handleFileDelete(index)} />}
                                </div>
                            </div>
                        )
                    })}
                </div>
            </Form.Group>

            <ImagePreviewModal
                show={showPreviewModal}
                handleClose={() => setShowPreviewModal(undefined)}
            />
        </div>
    )
}