import { useEffect, useState } from "react";
import { Button, Form, Offcanvas } from "react-bootstrap";
import Select from "react-select"
import DatePicker from "react-datepicker";
import moment from "moment";
import { AuthService } from "../../Services/authServices";
import toast from "react-hot-toast";


interface IEditUserInfoModal {
    show: any,
    handleClose: any,
    data: any,
    reload: any
}

export default function EditUserInfoModal({ show, handleClose, data, reload }: IEditUserInfoModal) {


    const [userData, setUserData] = useState<any>();

    const handleChangeValue = (e: any) => {
        setUserData({ ...userData, [e.target.name]: e.target.value })
    }


    function fileToDataUri(file: any) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = () => {
                resolve(reader.result);
            };

            reader.onerror = reject;

            reader.readAsDataURL(file);
        });
    }


    const handleFile = (file: any) => {
        fileToDataUri(file).then((res) => {
            setUserData({ ...userData, image: res })
        }).catch(error => {
            console.error('Error converting file to Data URI:', error);
        });
    }

    const updateUser = async () => {
        const payload: any = {
            name: userData?.name,
            email: userData?.email,
            dob: userData?.dob,
            gender: userData?.gender,
            mobile: userData?.mobile,
            image: userData?.image
        }

        toast.promise(AuthService.updateuser(payload).then((res) => {
            if (res.status === 200) {
                toast.success("")
                handleClose();
                reload();
            }
        }), {
            loading: 'Loading',
            success: 'User details updated successfully',
            error: 'Unable to update details',
        });

    }

    useEffect(() => {
        if (data) {
            setUserData(data)
        }
    }, [data])

    return (
        <>
            <Offcanvas
                show={show}
                onHide={handleClose}
                animation={true}
                placement="end"
            >
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Edit Details</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div style={{ minHeight: "85vh" }}>
                        <Form.Group className="mb-3">
                            <Form.Label>Name</Form.Label>
                            <Form.Control name="name" defaultValue={userData?.name} onChange={(e: any) => handleChangeValue(e)} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Email</Form.Label>
                            <Form.Control name="email" defaultValue={userData?.email} onChange={(e: any) => handleChangeValue(e)} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Dob</Form.Label>
                            <div>
                                <DatePicker
                                    selected={moment(userData?.dob).toDate()}
                                    onChange={(date: any) => setUserData({ ...userData, dob: date })}
                                    required
                                    name="dob"
                                    className="form-control"
                                />
                            </div>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Gender</Form.Label>
                            <Select
                                options={[
                                    {
                                        label: "Male",
                                        value: "male"
                                    },
                                    {
                                        label: "Female",
                                        value: "female"
                                    }
                                ]}
                                defaultValue={[{ label: userData?.gender, value: userData?.gender }]}
                                onChange={(e: any) => setUserData({ ...userData, gender: e.value })}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Mobile</Form.Label>
                            <Form.Control name="mobile" defaultValue={userData?.mobile} onChange={(e: any) => handleChangeValue(e)} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Profile Image</Form.Label>
                            <Form.Control type="file" accept="image/*" name="image" onChange={(e: any) => handleFile(e.target.files[0])} />
                        </Form.Group>
                    </div>

                    <Button variant="outline-danger" className="w-100" onClick={updateUser}>Save</Button>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}