import { Image, Modal } from "react-bootstrap"

interface IImagePreviewModal {
    show: any
    handleClose: any
}

export default function ImagePreviewModal({ show, handleClose }: IImagePreviewModal) {
    return (
        <Modal size="lg" show={show ? true : false} onHide={handleClose} animation={true} centered>
            <Modal.Header closeButton>
                {/* <Modal.Title>Modal heading</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
                <div>
                    <Image src={show} width="100%" />
                </div>
            </Modal.Body>
        </Modal>
    )
}