import React, { useState } from 'react';
import { IOptionsData } from '../../view/Private/Exam/ExamQuestionBody/ExamQuestion/ExamQuestion.options';
import ChoiceOption from './Choice.Option';
import ChoiceBooleanOption from './ChoiceBoolean.option';
import ChoiceTextOption from './ChoiceText.option';
import TextOption from './Text.Option';
import OptionTrueFalseWithNooption from './OptionTrueFalseWithNooption';
import FileUpload from '../Dropzone/dropzone';
import FileUploadAllowed from './fileUploadAllowed';

interface IRenderOption {
    choice?: string
    value?: string
    optionId: string
    onChange: any
    index: number
    defaultValue?: IOptionsData
    boolean?: boolean
    truefalsewithdontknow?: boolean
    fileUploadAllowed: any,
    disabled: boolean
}


export default function RenderOption(props: IRenderOption) {

    return (
        <>
            {
                props.fileUploadAllowed === true ?
                    <>
                        <FileUploadAllowed
                            label={props.value}
                            count={props.index}
                            onChange={(e: any) => props.onChange(e, "fileUploadAllowed")}
                            optionId={props.optionId}
                            defaultValue={props.defaultValue}
                            fileUploadAllowed={props.fileUploadAllowed}
                            disabled={props.disabled}
                        />
                    </> :
                    props.truefalsewithdontknow === true ?
                        <>
                            <OptionTrueFalseWithNooption
                                choiceLabel={props.value}
                                count={props.index}
                                onChange={(e: any) => props.onChange(e, "truefalsewithdontknow")}
                                optionId={props.optionId}
                                defaultValue={props.defaultValue}
                                trueorfalsewithdontknow={props.truefalsewithdontknow}
                                disabled={props.disabled}

                            />
                        </>
                        :
                        props.boolean === true ?
                            <>
                                <ChoiceBooleanOption
                                    choiceLabel={props.value}
                                    count={props.index}
                                    onChange={(e: any) => props.onChange(e, "boolean")}
                                    optionId={props.optionId}
                                    defaultValue={props.defaultValue}
                                    boolean={props.boolean}
                                    disabled={props.disabled}

                                />
                            </>
                            :
                            props.value && props.choice ?
                                <>
                                    <ChoiceTextOption
                                        choiceLabel={props.choice}
                                        textLabel={props.value}
                                        count={props.index}
                                        optionId={props.optionId}
                                        onChange={(e: any) => props.onChange(e, "choicewithtext")}
                                        defaultValue={props.defaultValue}
                                        disabled={props.disabled}


                                    />
                                </>
                                : props.value ?
                                    <>
                                        <TextOption
                                            label={props.value}
                                            count={props.index}
                                            optionId={props.optionId}
                                            onChange={(e: any) => props.onChange(e, "text")}
                                            defaultValue={props.defaultValue}
                                            disabled={props.disabled}

                                        />

                                    </>
                                    : props.choice ?
                                        <>
                                            <ChoiceOption
                                                label={props.choice}
                                                count={props.index}
                                                optionId={props.optionId}
                                                onChange={(e: any) => props.onChange(e, "choice")}
                                                defaultValue={props.defaultValue}
                                                disabled={props.disabled}


                                            />
                                        </>

                                        : <>
                                        </>

            }
        </>
    )
}
