import { useState } from "react";
import { Button, Card, Col, FloatingLabel, Form, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { AuthService } from "../../Services/authServices";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Lottie from "lottie-react";
import SignupAnimation from "../../assets/lottie/user-registration.json"

export default function SignUp() {

    const navigate = useNavigate();

    const onChangeValue = (e: any) => {
        setLoginData({ ...loginData, [e.target.name]: e.target.value });
    };

    const [selectedDate, setSelectedDate] = useState(null);
    const handleDateChange = (date) => {
        setSelectedDate(date);
        loginData.dob = date;
    };

    const [loginData, setLoginData] = useState<any>({
        name: "",
        last_name: "",
        email: "",
        dob: "",
        gender: "",
        mobile: "",
        password: "",
        cnf_password: "",
        image: "",
        city: "",
    });

    const signupSubmit = async (e: any) => {
        e.preventDefault();

        if (loginData.password !== loginData.cnf_password) {
            toast.error("Password and Confirm Password not matched");
            return;
        }

        await AuthService.signup({
            name: loginData.name,
            last_name: loginData.last_name,
            email: loginData.email,
            dob: loginData.dob,
            gender: loginData.gender,
            mobile: loginData.mobile,
            password: loginData.password,
            city: loginData.city,
        }).then((res) => {
            if (res.status == 200) {
                toast.success("Signup successfully");
                window.location.href = "/login";
            }
        }).catch(e => {
            toast.error(e.response.data);
        })
    };




    return (
        <>
            <Row>
                <Col md={8}>
                    <div style={{ height: "100vh", backgroundColor: '#eeeeee' }}>
                        <div className="d-flex justify-content-center align-items-center pt-5">
                            <Lottie animationData={SignupAnimation} style={{ height: "500px" }} loop={true} height={100} width={100} />
                        </div>
                    </div >
                </Col>
                <Col md={4} className="pt-4">
                    <h4 className="text-center">Create a new account</h4>
                    <p className="text-center text-secondary">Enter your details to register</p>
                    <FloatingLabel
                        controlId="floatingName"
                        label="Name"
                        className="mb-2"
                    >
                        <Form.Control
                            type="text"
                            required
                            placeholder="Name"
                            name="name"
                            value={loginData.name}
                            onChange={onChangeValue}
                        />
                    </FloatingLabel>
                    <FloatingLabel
                        controlId="floatingLastName"
                        label="Last Name"
                        className="mb-2"
                    >
                        <Form.Control
                            type="text"
                            required
                            placeholder="Last Name"
                            name="last_name"
                            value={loginData.last_name}
                            onChange={onChangeValue}
                        />
                    </FloatingLabel>{" "}
                    <FloatingLabel
                        controlId="floatingEmail"
                        label="Email"
                        className="mb-2"
                        style={{ zIndex: "0" }}
                    >
                        <Form.Control
                            type="email"
                            required
                            placeholder="Email"
                            name="email"
                            value={loginData.email}
                            onChange={onChangeValue}
                        />
                    </FloatingLabel>
                    <FloatingLabel
                        controlId="floatingDob"
                        label="DOB:"
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                        className="mb-2 p-2 border rounded "
                    >
                        <div
                            style={{
                                display: "inline-block",
                                border: "none",
                                zIndex: "99",
                                marginRight: "10px ",
                            }}
                        >
                            <DatePicker
                                selected={selectedDate}
                                onChange={handleDateChange}
                                required
                                name="dob"
                                value={loginData.dob}
                                className="form-control"
                            />
                        </div>
                    </FloatingLabel>
                    <FloatingLabel
                        controlId="floatingGender"
                        label="Gender"
                        className="mb-2"
                        style={{ zIndex: "0" }}
                    >
                        <Form.Select
                            name="gender"
                            value={loginData.gender}
                            onChange={onChangeValue}
                        >
                            <option value="">-</option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                        </Form.Select>
                    </FloatingLabel>
                    <FloatingLabel
                        controlId="floatingMobile"
                        label="Mobile"
                        className="mb-2"
                        style={{ zIndex: "0" }}
                    >
                        <Form.Control
                            type="tel"
                            required
                            placeholder="Mobile"
                            name="mobile"
                            value={loginData.mobile}
                            onChange={onChangeValue}
                        />
                    </FloatingLabel>
                    <FloatingLabel
                        controlId="floatingCity"
                        label="City"
                        onChange={onChangeValue}
                    >
                        <Form.Control
                            value={loginData.city}
                            required
                            type="city"
                            placeholder="City"
                            name="city"
                        />
                    </FloatingLabel>
                    <FloatingLabel
                        controlId="floatingPassword"
                        label="Password"
                        className="mb-2 mt-2"
                        onChange={onChangeValue}
                        style={{ zIndex: "0" }}
                    >
                        <Form.Control
                            value={loginData.password}
                            required
                            type="password"
                            placeholder="Password"
                            name="password"
                        />
                    </FloatingLabel>

                    <FloatingLabel
                        controlId="floatingPassword"
                        label="Confirm Password"
                        className="mb-2"
                        onChange={onChangeValue}
                        style={{ zIndex: "0" }}
                    >
                        <Form.Control
                            value={loginData.cnf_password}
                            required
                            type="password"
                            placeholder="Confirm Password"
                            name="cnf_password"
                        />
                    </FloatingLabel>

                    <Button
                        variant="primary"
                        type="submit"
                        className="w-100 mt-4"
                        size="lg"
                        onClick={signupSubmit}
                    >
                        <span className="xcn-text-14">Signup</span>
                    </Button>

                    <hr />
                    <div className="d-flex justify-content-center">
                        <span className="text-center">Already have an account? <span className="text-primary text-bold" style={{ cursor: "pointer" }} onClick={() => navigate('/login')}>Login</span></span>
                    </div>
                </Col>
            </Row >
        </>
    )
}